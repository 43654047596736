<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                    <li>3.交运智慧湾：https://jyzhw.jy-wisdombay.cn/</li>
                    <li>4.智慧七立方：https://wis777.kefan.com/</li>
                    <li>5.科房旗下园区：https://kf.kefan.com/</li>
                  </ul>
                </p>
              </div>
              <!-- 报修管理 -->
              <div class="range" id="bxgl">
                <h4 class="title">报修管理</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击左侧栏目“报修管理”进入报修界面（如图3.1）
                </p>
                <el-image 
                style="width:100%"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.1</p>
              </div>

              <!-- 报修补录 -->
              <div class="range" id="bxbl">
                <h4 class="title">报修补录</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击页面中间按钮“报修补录”（如图3.2）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.2</p>
                <p class="describe">
                     2.跳出报修故障方框（如图3.3）填入故障相关内容点击最下方“立即创建”即可完成
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.3</p>
              </div>
              <!-- 报修记录查看 -->
              <div class="range" id="bxjlck">
                <h4 class="title">报修记录查看</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击页面中间按钮“报修记录”（如图3.4）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.4</p>
                <p class="describe">
                  2.跳转至报修记录界面（如图3.5），可在页面上侧进行状态分类筛选，报修单的右侧黄色方框“处理”可对已受理的报修记录填写报修金额和备注（如图3.6）
                </p>
                <el-image 
                style="width:100%"
                :src="img.eurl[0]"
                :preview-src-list="img.eurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.5</p>
                <el-image 
                style="width:100%"
                :src="img.furl[0]"
                :preview-src-list="img.furl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.6</p>
              </div>
               <!-- 报修统计 -->
              <div class="range" id="bxtj">
                <h4 class="title">报修统计</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击页面上方蓝色按钮“统计图表”（如图3.7）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.gurl[0]"
                :preview-src-list="img.gurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.7</p>
                <p class="describe">
                     2.跳出报修总数趋势图和报修项目数量统计并可筛选目标日期（如图3.8）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.hurl[0]"
                :preview-src-list="img.hurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.8</p>
              </div>
              <!-- 快速报修处理 -->
              <div class="range" id="ksbxcl">
                <h4 class="title">快速报修处理</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.也可点击报修管理页面最右侧栏目对最新报修记录进行快速处理（如图3.9）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.iurl[0]"
                :preview-src-list="img.iurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图3.9</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "报修管理",
          target: "#bxgl",
        },
        {
          name: "报修补录",
          target: "#bxbl",
        },
        {
          name: "报修记录查看",
          target: "#bxjlck",
        },
        {
          name: "报修统计",
          target: "#bxtj",
        },
        {
          name: "快速报修处理",
          target: "#ksbxcl",
        },
      ],
      link: 0,
      img: {
        aurl: [this.$img + "bxgl/3.1.png"],
        burl: [this.$img + "bxgl/3.2.png"],
        curl: [this.$img + "bxgl/3.3.png"],
        durl: [this.$img + "bxgl/3.4.png"],
        eurl: [this.$img + "bxgl/3.5.png"],
        furl: [this.$img + "bxgl/3.6.png"],
        gurl: [this.$img + "bxgl/3.7.png"],
        hurl: [this.$img + "bxgl/3.8.png"],
        iurl: [this.$img + "bxgl/3.9.png"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 4) {
        this.tolink(0);
      } else if (result >= 4 && result < 16) {
        this.tolink(1);
      } else if (result >= 16 && result < 40) {
        this.tolink(2);
      } else if (result >= 40 && result < 73) {
        this.tolink(3);
      } else if (result >= 73 && result < 91) {
        this.tolink(4);
      } else {
        this.tolink(5);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
</style>